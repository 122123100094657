'use strict';

var theme = {
    init: function () {
        // theme.stickyHeader();
        theme.offCanvas();
        theme.spyScroll();
        theme.pageProgress();
        theme.bsTooltips();
    },

    /**
     * Sticky Header
     * Enables sticky behavior on navbar on page scroll
     * Requires assets/js/vendor/headhesive.min.js
     */
    stickyHeader: () => {
        var navbar = document.querySelector('.navbar');
        if (navbar == null) return;

        var options = {
            offset: 350,
            offsetSide: 'top',
            classes: {
                clone: 'navbar-clone fixed',
                stick: 'navbar-stick',
                unstick: 'navbar-unstick',
            },
            onStick: function () {
                var navbarClonedClass = this.clonedElem.classList;
                if (navbarClonedClass.contains('transparent') && navbarClonedClass.contains('navbar-dark')) {
                    this.clonedElem.className = this.clonedElem.className.replace('navbar-dark', 'navbar-light');
                }
            },
        };

        var banner = new Headhesive('.navbar', options);
    },

    /**
     * Offcanvas
     * Enables offcanvas-nav, closes offcanvas on anchor clicks, focuses on input in search offcanvas
     */
    offCanvas: () => {
        var navbar = document.querySelector('.navbar');
        if (navbar == null) return;
        const navOffCanvasBtn = document.querySelectorAll('.offcanvas-nav-btn');
        const navOffCanvas = document.querySelector('.navbar:not(.navbar-clone) .offcanvas-nav');
        const bsOffCanvas = new bootstrap.Offcanvas(navOffCanvas, { scroll: true });
        const scrollLink = document.querySelectorAll('.onepage .navbar li a.scroll');
        const searchOffcanvas = document.getElementById('offcanvas-search');
        navOffCanvasBtn.forEach((e) => {
            e.addEventListener('click', (event) => {
                bsOffCanvas.show();
            });
        });
        scrollLink.forEach((e) => {
            e.addEventListener('click', (event) => {
                bsOffCanvas.hide();
            });
        });
        if (searchOffcanvas != null) {
            searchOffcanvas.addEventListener('shown.bs.offcanvas', function () {
                document.getElementById('search-form').focus();
            });
        }
    },

    /**
     * Spy Scroll
     * Highlights the active nav link while scrolling through sections
     */
    spyScroll: () => {
        let section = document.querySelectorAll('section[id]');
        let navLinks = document.querySelectorAll('.nav-link.scroll');
        window.onscroll = () => {
            section.forEach((sec) => {
                let top = window.scrollY; //returns the number of pixels that the document is currently scrolled vertically.
                let offset = sec.offsetTop - 0; //returns the distance of the outer border of the current element relative to the inner border of the top of the offsetParent, the closest positioned ancestor element
                let height = sec.offsetHeight; //returns the height of an element, including vertical padding and borders, as an integer
                let id = sec.getAttribute('id'); //gets the value of an attribute of an element
                if (top >= offset && top < offset + height) {
                    navLinks.forEach((links) => {
                        links.classList.remove('active');
                        document.querySelector(`.nav-link.scroll[href*=${id}]`).classList.add('active');
                        //[att*=val] Represents an element with the att attribute whose value contains at least one instance of the substring "val". If "val" is the empty string then the selector does not represent anything.
                    });
                }
            });
        };
    },

    /**
     * Page Progress
     * Shows page progress on the bottom right corner of pages
     */
    pageProgress: () => {
        var progressWrap = document.querySelector('.progress-wrap');
        if (progressWrap != null) {
            var progressPath = document.querySelector('.progress-wrap path');
            var pathLength = progressPath.getTotalLength();
            var offset = 50;
            progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
            progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
            progressPath.style.strokeDashoffset = pathLength;
            progressPath.getBoundingClientRect();
            progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';
            window.addEventListener('scroll', function (event) {
                var scroll = document.body.scrollTop || document.documentElement.scrollTop;
                var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
                var progress = pathLength - (scroll * pathLength) / height;
                progressPath.style.strokeDashoffset = progress;
                var scrollElementPos = document.body.scrollTop || document.documentElement.scrollTop;
                if (scrollElementPos >= offset) {
                    progressWrap.classList.add('active-progress');
                } else {
                    progressWrap.classList.remove('active-progress');
                }
            });
            progressWrap.addEventListener('click', function (e) {
                e.preventDefault();
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            });
        }
    },

    /**
     * Bootstrap Tooltips
     * Enables Bootstrap tooltips
     * Requires Poppers library
     */
    bsTooltips: () => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl, {
                trigger: 'hover',
            });
        });
        var tooltipTriggerWhite = [].slice.call(document.querySelectorAll('[data-bs-toggle="white-tooltip"]'));
        var tooltipWhite = tooltipTriggerWhite.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl, {
                customClass: 'white-tooltip',
                trigger: 'hover',
                placement: 'left',
            });
        });
    },
};

theme.init();
